.table-fs-14 {
    thead,
    tbody {
        th,
        td {
            font-size: 0.875rem;
        }
    }
}

@include mq("tablet", max) {
    .input-label {
        font-size: 1rem;
        text-align: left;
    }
    .table {
        &.file-table {
            tr {
                border-bottom: 1px solid #ccc;
                th,
                td {
                    position: relative;
                    border: none;
                    display: block;

                    &:last-child {
                        padding: 0;
                    }

                    i.fas {
                        position: absolute;
                        bottom: 1.85rem;
                        right: 1rem;
                        right: 1rem;
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.alert-msg-2 {
    position: fixed !important;
    top: 50%;
    height: 150px;
    text-align: center;
    left: 50%;
    width: 300px;
    background-color: #2d52a4 !important;
    color: white;
    z-index: 5;
    transform: translate(-50%, -50%);
}


.landlord-payment-table{
    .MuiTableCell-body{
        border-right: 1px solid rgba(224, 224, 224, 1);
        padding: 10px 16px;
        &:last-child{
            border-right: 0;
        }
    }
    .MuiTableCell-head{
        border-right: 1px solid rgba(224, 224, 224, 1);
        background-color: #dbe7f1;
        padding: 10px 16px;
        &:last-child{
            border-right: 0;
        }
    }
    .MuiPaper-elevation4 {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .MuiToolbar-regular {
        min-height: 60px !important;
        background: #eaf1f6;
    }
}

.landlord-payment-note{
    background: #f1f0f6;
    padding: 20px 15px;
    margin-bottom: 20px;
}

.MuiPaper-elevation1 .MuiTabs-root {
    min-height: 35px;
}

.MuiPaper-elevation1 .MuiTab-textColorPrimary.Mui-selected {
    background: #005191;
    border-radius: 100px;
    color: #fff;
}

.MuiPaper-elevation1 .MuiTab-root {
    min-width: 100px;
    min-height: 35px;
    font-size: 13px;
}

.MuiPaper-elevation1 .MuiTouchRipple-root {
    border-radius: 100px;
}

.MuiPaper-elevation1 .MuiTabs-indicator {
    display: none;
}

.MuiPaper-elevation1 {
    border-radius: 100px;
}

.landlord-payment-note {
    background: #f8ecb5;
    padding: 8px 8px;
    margin-bottom: 15px;
    border: 1px solid #d9c569;
    h6{
        font-size: 15px;
    }
}

.note-instruction{
    margin-bottom: 0;
    padding-left: 0;
    li{
        font-size: 14px;
        display: inline-block;
        &:first-child{
            margin-right: 15px;
        }
    }
}

.status-red {
    background-color: #ffcdd2;
    color: #c63737;
}

.status-green {
    background-color: #c8e6c9;
    color: #256029;
}

.status-yellow {
    background-color: #feedaf;
    color: #8a5340;
}

.status-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
    text-align: center;
}

.note-list{
    li{
        font-size: 14px;
    }
}