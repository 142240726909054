
.profile-title{
    font-size: 1.2rem;
    color: $primary;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
}

/*record detial page css START*/
.record-detail-main{
    .box-gray{
        background: #f1f0f6 !important;
    }
    .profile-title{
        font-size: 1.2rem;
        background: #fff !important;
        padding-bottom: .3125rem;
    }
    .box-details{
        padding:0rem .9375rem;
        label{
            font-size: .875rem;
            margin-bottom: 0;
            position: relative;
        }
        .form-control{
            height: 1.875rem;
            font-size: .875rem;
        }
    }
    .img-box{
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        transition: all 0.5s ease-out;
        &:hover{
            .custom-file-upload{
                display: block;
            }
        }
    }
}

.validation-text{
    .alert-small-text{
        position: absolute;
    }
}


.alert-small-text{
    color: $red;
    font-size: .75rem;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-weight: bold;
    position: relative;
}

/*record detial page css END*/


.MuiTooltip-tooltip{
    background-color: $black !important;
    font-size: 12px !important;
}

.MuiTooltip-arrow{
    color: $black !important;
}

// .details-page{
//     margin-top: 7rem;
// }

.field-hightlight{
    .form-control{
        border:1px solid #4caf50;
    }
}

// floating btn
.whoc-float-btn{
    width: 50px;
    height: 50px;
    border-radius: 100% !important;
    color: #fff;
    line-height: normal !important;
    padding: .3125rem !important;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
    min-width: 50px !important;

    &.btn-primary{
        color: #fff !important;
        background-color: #005191 !important;
        border-color: #005191 !important;
        &:hover{
            background-color: #064b82 !important;
            border-color: #064b82 !important;
            color: $white !important;
        }
        &:focus, &.focus, &:active{
            background-color: #064b82 !important;
            border-color: #064b82 !important;
            box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
            color: $white !important;
        }
    }

    &.btn-secondary {
        color: #fff !important;
        background-color: #ffb351 !important;
        border-color: #ffb351 !important;
        &:hover{
            background-color: #ec9f3c !important;
            border-color: #ec9f3c !important;
            color: $white !important;
        }
        &:focus, &.focus, &:active{
            background-color: #ec9f3c !important;
            border-color: #ec9f3c !important;
            box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
            color: $white;
        }
    }

    i{
        font-size: 1.75rem;
    }
}

/*Irvine city css START*/
.note-highlight{
    color: #FF0000;
}

p{
    font-size: .9375rem;
}

.qualify-questions{
    label{
        font-size: .9375rem;
        font-weight: 500;
        position: relative;
    }
    .custom-control-label{
        font-weight: 400;
    }
    .form-row > .col, .form-row > [class*="col-"] {
        padding-right: 15px;
        padding-left: 15px; 
    }
    .border-right{
        @media only screen and (max-width: 992px) {
            display: none;
        }
    }
}
.qualify-info-table{
    th{
        vertical-align: middle !important;
    }
    th, td{
        text-align: center;
        padding: .3125rem;
    }
}

/*****************************************
  upload button styles
******************************************/
.file-upload {
	position: relative;
	display: inline-block;
}

.file-upload__label {
  display: block;
  padding: .125rem .375rem;
  color: #fff;
  background: #50ad90;
  transition: background .3s;
  margin-bottom: 0;
  
  &:hover {
     cursor: pointer;
     background: #47a085;
  }
}
    
.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width:0 !important;
    height: 100%;
    opacity: 0;
}
.red{
    color: #dc3545;
}

.asterisk{
    color: #d8000c;
    font-size: 8px;
    position: relative;
    right: 3px;
    top: -8px;
}

.irvine-loader{
    &.MuiBackdrop-root {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;
        z-index: 1201 !important;
        .MuiCircularProgress-root{
          color: $yellow;
        }
    }
}


.box-details{
    .custom-control-label::after{
        width: .8125rem;
        height: .8125rem;
    }
    .custom-control-label::before{
        width: .8125rem;
        height: .8125rem;
    }
}

.success-icon{
    font-size: 80px;
    color: $primary;
}
/*Irvine city css END*/


.file-allowed{
    font-size: 13px;
    color: #5c9ad0;
    float: right;
    display: inline-block;
    font-weight: 500;
}

.sample-pdf {
    float: right;
	font-size: 14px;
	color: #f5a033;
	text-decoration: underline;
}

.landlord-table{
    .MuiTableCell-head{
        border: 1px solid #c2c4c5 !important;
        background-color: #dfe0e1 !important;
    }
    .MuiTableCell-body {
        border: 1px solid #c2c4c5 !important;
    }
}

.thankyoudialog{
    .MuiDialog-paper{
        z-index: 9999 !important;
    }
}

.wh-note {
    background: #005191;
    padding: 5px 2px;
    color: #fff;
    font-size: 16px;
}

.wh-note span {
    font-weight: 700;
    padding-left: .625rem;
}
.note-list ul {
    padding-left: 35px;
}

.assistoc-note p {
    font-size: 14px;
    color: red;
}